
import { defineComponent } from "vue";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import * as Yup from "yup";
import { ElMessage } from "element-plus";
import { useField, useForm } from "vee-validate";

declare const window: any;
export default defineComponent({
  name: "settingPage",

  data() {
    return {
      updateEventID: "",
      updateGroupID: "",
      copyMessageText:"",
      copyMessageText2:"",
      displayPrizeEvent: "", //30,31,32,33
      prizeData: [],
      trophyArray: [],
      userProfile: [],
      area: {},
      mobile: "",
      gender: {},
      birthdayDate: "",
      practice: {},
      bio: "",
      fbid: "no",
      files: [] as any,
      url: "",
      messageList: []
    };
  },
  watch: {
  },
  computed: {
    uidValue() {
      return store.state.uid;
    },
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({name: 'login'});
    } else if (store.state.uid != 1) {
      this.$router.push({name: '404'});
    }

    if (this.uidValue != '1') {
      this.$router.push({name: '404'});
    }

    this.getNoticeMessage()
    //this.displayPrizeList()
  },
  methods: {
    previewFiles(event) {
      //1048576 = 1000kb
      if (event.target.files[0].size > 1048576) {
        ElMessage.error(this.$t("avatarSize"));
      } else if (
        event.target.files[0].type != "image/jpeg" &&
        event.target.files[0].type != "image/png"
      ) {
        ElMessage.error(this.$t("avatarType"));
      } else {
        this.files = event.target.files[0];
      }
      this.url = URL.createObjectURL(this.files);
    },
    switchPlayers() {
      console.log("Switch Players")
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=switchPlayers",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
        })
    },
    updateEvent() {
      console.log("Update Event"+this.updateEventID)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=updateEvent&eventID="+this.updateEventID,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          for (let i = 0; i < response.data.length; i++) {
              setTimeout(() => {
                  console.log(response.data[i])
                  this.updateGroup(response.data[i])
              }, i*2000);
          }
        })
    },
    updateGroup(groupInput) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=updateGroup&groupInput="+groupInput,
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          if(response.data == true) {
            ElMessage.success(this.$t("updated")+": "+groupInput);
          }
          console.log(response.data)
        })
    },
    getNoticeMessage() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=getNoticeMessage",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          this.messageList = response.data
        })
    },
    displayPrizeList() {
      console.log("displayPrizeList"+this.displayPrizeEvent)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=displayPrizeList",
          JSON.stringify({ uid: this.uidValue, eventID: this.displayPrizeEvent })
        )
        .then((response) => {
          console.log("response.data")
          console.log(response.data)
          response.data[0].forEach(element => {
            try {
              element['winner_address'] = decodeURIComponent(element['winner_address']);
            } catch (e) {
              console.error("URI decoding failed:", e);
            }
              //element['winner_address'] = safeDecodeURIComponent(element['winner_address']);
          });
          this.prizeData = response.data[0]
          this.trophyArray = response.data[1]
        })
    },
    updateUserAddress(item) {
      var updateUid;
      if (item.doubleWinner_uid) {updateUid = item.doubleWinner_uid} else {updateUid = item.winner_uid}
      console.log(updateUid)
      console.log(item.winner_address)
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=updateUserAddress",
          JSON.stringify({ uid: updateUid, address: encodeURIComponent(item.winner_address) })
        )
        .then((response) => {
          if (response.data == "success") {
            ElMessage.success(this.$t("updated"));
          }
        })

    },
    copyMessage(item){
      var placeText = "";var placeTextEng="";
      
      if (item.prizePlace == 1) {placeText = "第一名";placeTextEng="1st Place"}
      else if (item.prizePlace == 2) {placeText = "第二名";placeTextEng="2nd Place"}
      else if (item.prizePlace == 3) {placeText = "第三名";placeTextEng="3rd Place"}
      var prizeText = "";var prizeTextEng = "";
      if (item.prizeMoney != 0) {
        prizeText = "獎金HK$"+item.prizeMoney+"將會透過轉數快發送，請提供轉數快收款號碼。<br>"
        prizeTextEng = "The prize money of HK$"+item.prizeMoney+" will be sent via FPS, please provide your FPS account number.<br>"
      }
      this.copyMessageText = "你好，恭喜你嬴得 RankOpen 香港網球比賽：<br>" +
              item.event_name+" "+item.tour_info.name+" "+item.name+" "+placeText+"！<br><br>" +
              "我們將安排順豐到付寄出獎座，請提供您的郵寄地址。<br>" +
              prizeText +
              "由於獎盃訂制需時，寄出後我們會再通知您。<br>" +
              "如不需要獎座或有任何提問請通知我們。<br>" +
              "感謝您支持並參加我們的比賽！<br><br>"+
              "Congratulations on winning the <br>"+
              item.event_name_en+" "+item.tour_info.name_en+" "+item.name+" "+placeTextEng+"！<br><br>" +
              "We’ll send the trophy via SF Express (cash on delivery). Please provide your mailing address.<br>"+
              prizeTextEng+
              "<br>The trophy will take some time to prepare. We will notify you once it has been shipped.<br>"+
              "Let us know if you have any questions or if you don’t need the trophy.<br>"+
              "Thank you for participating RankOpen!<br>"
      navigator.clipboard.writeText(this.copyMessageText.replace(/<br>/g, '\n'))
      .then(() => {
        ElMessage.success(this.$t("copied"));
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    copyMessage2(item){
      this.copyMessageText2 = "恭喜您出色的表現勝出 RankOpen 香港網球比賽！您獲得 HK$"+item.prizeMoney+" 的獎金。<br>" +
              "Congratulations on your success in the RankOpen Hong Kong tennis tournament! You will received a prize of HK$"+item.prizeMoney+"."
      navigator.clipboard.writeText(this.copyMessageText2.replace(/<br>/g, '\n'))
      .then(() => {
        ElMessage.success(this.$t("copied"));
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    copyNoticeMessage(item){
      navigator.clipboard.writeText(item.replace(/<br>/g, '\n'))
      .then(() => {
        ElMessage.success(this.$t("copied"));
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
    },
    testWhatsapp(){
      console.log("testWhatsapp")
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=whatsapp_api&action=test",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
        })
    },
    downloadCSV() {
      console.log("Download CSV")
      axios
        .post(
          process.env.VUE_APP_API_URL + "&mode=jwt&tab=cp&action=downloadCSV",
          JSON.stringify({ uid: this.uidValue })
        )
        .then((response) => {
          console.log(response.data)
          // Convert the CSV data to a Blob
          const blob = new Blob([response.data], { type: 'text/csv;charset=big5;' });

          // Create a link element
          const link = document.createElement('a');
          if (link.download !== undefined) { // Feature detection
            // Create a URL for the Blob and set it as the href attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'NewContact.csv');
            
            // Append the link to the body
            document.body.appendChild(link);
            
            // Trigger the download by simulating a click
            link.click();
            
            // Clean up and remove the link
            document.body.removeChild(link);
          }
        })
    }
  },
});
